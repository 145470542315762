import { FC, useState } from "react";
import { useLoaderData, useNavigate, useRouteLoaderData, useSearchParams } from "react-router-dom";
import { Apis } from "../../../api/apis";
import { ExplorationModel, ProjectModel } from "../../../api/apimodels";
import { StandardCard } from "../../../components/common/StandardCard";
import { Avatar, Box, Button, Container, ListItem, ListItemAvatar, ListItemText, SxProps, Theme, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { EditDetailsDialog } from "../../../components/common/EditDetailsDialog";
import { useTheme } from "@mui/material";
import { DateText } from "../../../components/common/DateText";
import { formatJsonDate } from "../../../util/dateformat";
import { ExplorationPage as ExplorationsPageInfo, datasetPath, explorationPath } from "../../PageInfo";
import { ApplicationActionTypes, useApplicationDispatch } from "../../../state/applicationstate";

export const ExplorationInfoTab: FC<{loading: boolean, setLoading: (v:boolean) => void, sx?: SxProps<Theme>}> = ({ loading, setLoading, sx }) => {  
  const { exploration } = useLoaderData() as { project: ProjectModel, exploration: ExplorationModel };
  const project = useRouteLoaderData('project') as ProjectModel;
  const [editOpen, setEditOpen] = useState(false);
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const theme = useTheme();
  const {name, description, created_at: createdAt} = exploration;
  const appDispatch = useApplicationDispatch();

  const onEditDialogClose = () => {
    setEditOpen(false);
  };

  const onClickShowDataset = () => {
    if (exploration.dataset!.parent_id) {
      const originalDatasetId = exploration.dataset!.root_id;
      const versionId = exploration.dataset!.id;
      navigate("/" + datasetPath(project.id!, originalDatasetId) + "?xv=" + versionId);
    } else {
      // The original dataset was used
      navigate("/" + datasetPath(project.id!, exploration.dataset!.id!));
    }
  };  

  const onEditDialogSave = (name: string, description: string) => {
    Apis.shared()
      .project.updateExploration(project.id!, exploration.id!, name, description)
      .then((result) => {
        navigate(".?" + searchParams.toString(), { replace: true });
        setEditOpen(false);
      })
      .catch((error) => {
        console.error(error);
        appDispatch({
          type: ApplicationActionTypes.addNotification,
          notification: {
            type: "error",
            message: `Save failed. Error: ${error}`
          }
        });
      });
  };

  const onEditDialogDelete = () => {
    Apis.shared().project.deleteExploration(project.id!, exploration.id!)
      .then(() => {
        setEditOpen(false);
        navigate("/" + explorationPath(project.id!));
      })
      .catch((error) => {
        console.error(error);
        appDispatch({
          type: ApplicationActionTypes.addNotification,
          notification: {
            type: "error",
            message: `Delete failed. Error: ${error}`
          }
        });
      });
  };

  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
    <StandardCard sx={{...sx}}>
      <ListItem>
        <ListItemAvatar>
        <Avatar sx={{ color: theme.palette.text.primary }}>
          {ExplorationsPageInfo.menuIcon}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={
          <Box component="span">
            <Typography component="span" sx={{ display: "block" }}>
              {description}
              {!!createdAt && <DateText>Created: {formatJsonDate(createdAt)}</DateText>}
            </Typography>
          </Box>
        }
      />
      </ListItem>
      <Stack direction="row" justifyContent="flex-end">
        <Button onClick={onClickShowDataset}>
          Show Dataset
        </Button>
        <Button onClick={() => { setEditOpen(true); }}>
          Edit
        </Button>
      </Stack>
      <EditDetailsDialog
        dialogTitle="Edit exploration"
        name={exploration.name}
        description={exploration.description}
        onClose={onEditDialogClose}
        onSave={onEditDialogSave}
        onDelete={onEditDialogDelete}
        open={editOpen}
      />
    </StandardCard>
    </Container>
  );
};