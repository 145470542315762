import { Button, Chip, Divider, Stack, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, styled, TextField } from "@mui/material";
import { useEffect } from "react";
import FileUploadButton from "../FileUploadButton";
import { parse } from 'csv-parse/browser/esm/sync';

const WideDialog = styled(Dialog)(({ theme }) => ({
  "& form.MuiDialog-paper": {
    minWidth: '800px',
    maxWith: '800px',
    [theme.breakpoints.down('md')]: {
      minWidth: '90%',
      maxWith: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '95%',
      maxWith: 'none'
    },
  }
}));

type EditDetailsDialogProps = {
  open: boolean;
  dialogTitle: string;
  keyLabel: string;
  valueLabel: string;
  value: [string, string][];
  onClose: () => void;
  onDelete: () => void;
  onSave: (value: [string, string][]) => void;
}

export function EditKVListDialog(props: EditDetailsDialogProps) {
  const { open, onClose, onSave, onDelete, dialogTitle, value, keyLabel, valueLabel } = props;
  const [ currentValue, setCurrentValue ] = useState(value);
  
  function onChangeKey(key: string, index: number) {
    const newValue = [...currentValue];
    newValue[index][0] = key;
    setCurrentValue(newValue);
  }

  function onChangeValue(v: string, index: number) {
    const newValue = [...currentValue];
    newValue[index][1] = v;
    setCurrentValue(newValue);
  }

  function onAddRow() {
    setCurrentValue([...currentValue, ["", ""]]);
  }

  function onDeleteRow(index: number) {
    const newValue = [...currentValue];
    newValue.splice(index, 1);
    setCurrentValue(newValue);
  }

  function onUploadCsv(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    e.stopPropagation();
    const { target } = e;
    const fileReader = new FileReader();
    if ((target.files?.length ?? 0) > 0) {
      const file = target.files![0];
      fileReader.onload = (e) => {
        const text = e.target?.result;
        if (text) {
          const records: string[][] = parse(text as string, { });
          if (records.length > 0 && !records.find((r) => (r.length !== 2))) {
            if (records[0][0].length < 16 && records[0][1].length < 16) {
              records.splice(0, 1);
            }
            setCurrentValue(records as [string, string][]);
          }
        }
      };
      fileReader.readAsText(file);
    }
  }

  return (
      <WideDialog
          open={open}
          onClose={onClose}
          PaperProps={{
              component: 'form',
              onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                  event.preventDefault();
                  onSave(currentValue);
              },
          }}
      >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
              {currentValue.map((v, i) => (
                <Box key={`row_${i}`}>
                  <Stack direction="row" alignItems="center">
                    <Chip label={`${i}`} sx={{mr: 2, borderRadius: 1}}/>
                    <Box flexGrow={1}>
                      <TextField required margin="dense" id="key" name="name" label={keyLabel} type="text" fullWidth value={currentValue[i][0]} onChange={(e) => { onChangeKey(e.target.value, i); }} variant="outlined" />
                      <TextField required multiline rows={4} margin="dense" id="value" name="description" label={valueLabel} type="text" value={currentValue[i][1]} onChange={(e) => { onChangeValue(e.target.value, i); }} fullWidth variant="outlined" />
                    </Box>
                    <Box sx={{mx: 2}}><Button onClick={() => (onDeleteRow(i))}>Delete</Button></Box>
                  </Stack>
                  {i < (currentValue.length - 1) && (
                    <Divider sx={{my: 2}} />
                  )}
                </Box>
              ))}
              <Stack sx={{mt: 2}}>
                <Button onClick={() => (onAddRow())}>Add entry</Button>
              </Stack>
          </DialogContent>
          <DialogActions>
              <Box sx={{flexGrow: 1, ml: 1}}>
              <FileUploadButton label="Upload CSV" onPick={onUploadCsv} color="primary" variant="text" noIcon />
              </Box>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit">Save</Button>
          </DialogActions>
      </WideDialog>
  );
}

type KVListControlProps = {
  id: string;
  className?: string;
  value: [string, string][];
  displayName: string;
  keyLabel: string;
  valueLabel: string;
  label: string;
  disabled?: boolean;
  onChange: (id: string, value: [string, string][]) => void;
};

export function KVListControl(props: KVListControlProps) {
  const { id, value, keyLabel, valueLabel, displayName, onChange } = props;
  const [showModal, setShowModal] = useState(false);

  function onClick() {
    setShowModal(true);
  }

  const onSave = useCallback((value: [string, string][]) => {
    onChange(id, value);
    setShowModal(false);
  }, [id, onChange]);

  const editDialog = useMemo(
    () => (<EditKVListDialog open={showModal} dialogTitle={displayName} keyLabel={keyLabel} valueLabel={valueLabel} value={value} onClose={() => setShowModal(false)} onDelete={() => setShowModal(false)} onSave={onSave} />), 
    [displayName, keyLabel, onSave, showModal, value, valueLabel]
  );

  return <>
    <Button onClick={onClick}>Edit topics</Button>
    {editDialog}
  </>
}